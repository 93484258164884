import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import ReactGA from '../analytics';

import { Modal, Backdrop, Fade } from '@material-ui/core';

import routes from '../routes/routes';
import redirects from '../routes/redirects';

import Footer from './Footer';
import Nav from './nav/nav';

import ContactForm from './contact-form/contact-form';
import Button from './button/button';

import { envUrl } from '../configs/MetalConfig';

const classes = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWrap: {
    // padding: 20,
    background: 'white',
    maxWidth: 500,
    width: '90%',
    margin: 'auto',
    borderRadius: 8,
    overflow: 'hidden',
  },
  modalButton: {
    background: 'white',
    color: 'black',
    position: 'absolute',
    top: 20,
    right: 20,
    padding: '15px 25px',
    borderRadius: 8,
    zIndex: 99999,
  },
  modalOpenButton: {
    fontWeight: 700,
    boxShadow: '1px 1px 17px #15151594',
    zIndex: 10000,
  },
};

function TransitionsModal({ children, open, handleClose }) {
  return (
    <div>
      <Modal
        style={classes.modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <>
          <Fade in={open}>{children}</Fade>
          <Button style={classes.modalButton} onClick={handleClose}>
            X
          </Button>
        </>
      </Modal>
    </div>
  );
}

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export default function App() {
  const FORM_ID = '1037';
  const [isSuccessMessage, setIsSuccessMessage] = useState(false); // manage is success message state
  const [messageSent, setMessageSent] = useState(false); // manage sent message state
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    ReactGA.modalview(`${window.location.pathname}contact-us`);
    ReactGA.event({
      category: 'ButtonClick',
      action: 'Clicked Contact Form',
      label: `Contact Form - ${window.location.pathname}`,
    });
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const { handleChange, isSubmitting, values, handleSubmit } = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      message: '',
      companyname: '',
      phone: '',
    },
    onSubmit: (
      { fullname, email, message, companyname, phone },
      { setSubmitting, resetForm },
    ) => {
      setSubmitting(true);
      // here we created a FormData field for each form field
      const bodyFormData = new FormData();
      bodyFormData.set('fullname', fullname);
      bodyFormData.set('email', email);
      bodyFormData.set('message', message);
      bodyFormData.set('companyname', companyname);
      bodyFormData.set('phone', phone);

      // here we sent
      axios({
        method: 'post',
        url: `${envUrl}wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
        data: bodyFormData,
      })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          resetForm();
          setSubmitting(false);
          setMessageSent(true);
          setIsSuccessMessage(true);
          ReactGA.event({
            category: 'ContactForm',
            action: 'Clicked Contact Form Success',
            label: `Contact Form - ${window.location.pathname}`,
          });
          setTimeout(() => {
            handleClose();
            setMessageSent(false);
          }, 3000);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          setSubmitting(false);
          setMessageSent(true);
          setIsSuccessMessage(false);
          ReactGA.event({
            category: 'ContactForm',
            action: 'Clicked Contact Form Error',
            label: `Contact Form - ${window.location.pathname}`,
          });
        });
    },
  });

  const formObj = useMemo(
    () => ({
      messageSent,
      isSuccessMessage,
      isSubmitting,
      values,
      handleSubmit,
      handleChange,
    }),
    [messageSent, isSuccessMessage, isSubmitting, values],
  );

  return (
    <div className="center">
      <Nav openModal={handleOpen} />
      <div>
        <Switch>
          <Route
            exact
            path="/stainless-steel-grade/:gradeId"
            render={props => (
              <Redirect
                to={`/inventory/stainless-steel/grade/${props.match.params.gradeId}`}
              />
            )}
          />

          <Route
            exact
            path="/alloy-steel-grade/:gradeId"
            render={props => (
              <Redirect
                to={`/inventory/alloy-steel/grade/${props.match.params.gradeId}`}
              />
            )}
          />
          <Route
            exact
            path="/titanium-grade/:gradeId"
            render={props => (
              <Redirect
                to={`/inventory/titanium/grade/${props.match.params.gradeId}`}
              />
            )}
          />
          <Route
            exact
            path="/cobalt-alloy-grade/:gradeId"
            render={props => (
              <Redirect
                to={`/inventory/cobalt/grade/${props.match.params.gradeId}`}
              />
            )}
          />
          <Route
            exact
            path="/nickel-grade/:gradeId"
            render={props => (
              <Redirect
                to={`/inventory/nickel/grade/${props.match.params.gradeId}`}
              />
            )}
          />
          {redirects.map((redirect, index) => (
            <Redirect
              key={`redirect-${index}`}
              from={redirect.oldPath}
              to={redirect.newPath}
            />
          ))}
          {routes.map((routeData, i) => (
            <Route
              exact={routeData.exact === false ? routeData.exact : true}
              key={`route-${i}`}
              path={routeData.path}
              render={otherProps => (
                <>
                  <ScrollToTopOnMount />
                  {ReactGA.pageview(otherProps.location.pathname)}
                  <routeData.component
                    {...otherProps}
                    meta={routeData.meta || null}
                    formObj={formObj}
                    openModal={handleOpen}
                  />
                </>
              )}
            />
          ))}
        </Switch>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 25,
          right: 25,
        }}
      >
        <Button
          style={classes.modalOpenButton}
          variant="contained"
          onClick={handleOpen}
        >
          Get Quote
        </Button>
        <TransitionsModal open={open} handleClose={handleClose}>
          <div style={classes.modalWrap}>
            <ContactForm
              messageSent={messageSent}
              isSuccessMessage={isSuccessMessage}
              isSubmitting={isSubmitting}
              values={values}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
            />
          </div>
        </TransitionsModal>
      </div>
      <Footer />
    </div>
  );
}
