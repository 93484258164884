import React from 'react';

import { Grid } from '@material-ui/core';

import { envUrl } from '../configs/MetalConfig';
import CardBody from './card-body/card-body';

const Footer = () => (
  <footer style={{ background: '#303030' }}>
    {/* <div className="call-out">
      <div className="">This is a callout!</div>
    </div> */}
    <div
      className=""
      style={{ maxWidth: 1400, margin: 'auto', padding: '80px 30px' }}
    >
      <Grid container spacing={8} alignItems="flex-start">
        <Grid item xs={12} lg={4}>
          <img
            alt="Universal Metals Logo"
            style={{
              maxHeight: 67,
              width: 'auto',
              background: 'white',
              padding: '5px 20px',
              borderRadius: 4,
              marginBottom: 10,
            }}
            src={`${envUrl}wp-content/uploads/2017/07/umnewlogonew.png`}
          />
          <h3 style={{ color: 'white', margin: 0 }}>Universal Metals</h3>
          <p style={{ color: 'white', margin: '10px 0 20px', fontSize: 18 }}>
            We are a next generation supplier of hard-metals to the aerospace
            industry as well as other critical application industries. We are
            committed to setting industry leading standards in all measures of
            business performance and customer service.
          </p>
          <img
            alt="AS9100 Certified"
            style={{ width: '100%' }}
            src={`${envUrl}wp-content/uploads/2017/07/as9100.jpg`}
          />
        </Grid>
        <Grid container item xs={12} lg={8} spacing={4} alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <CardBody title="Universal Metals Corona">
              Address: 1020 Railroad St. Corona, CA 92882
              <br />
              Office Phone: (951) 271-3950 <br />
              Toll free: (800) 862-6159
              <br />
              Fax: (951) 271-3940
            </CardBody>
          </Grid>
        </Grid>
      </Grid>
    </div>
    <div
      className="bottom-bar"
      style={{
        background: 'rgb(56, 48, 103)',
        padding: '8px 4px',
        color: 'white',
        textAlign: 'center',
      }}
    >
      2024 © Universal Metals. All Rights Reserved.
    </div>
  </footer>
);

export default Footer;
