import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import { envUrl } from '../../configs/MetalConfig';

const pathsObj = {
  '/': { name: 'Home', isLinked: true },
  //   '/inventory': { name: 'Inventory', isLinked: true },
  '/inventory/stainless-steel': { name: 'Stainless Steel', isLinked: true },
  '/inventory/stainless-steel/specs': { name: 'Specs', isLinked: false },
  '/inventory/titanium': { name: 'Titanium', isLinked: true },
  '/inventory/alloy-steel': { name: 'Alloy Steel', isLinked: true },
  '/inventory/cobalt-alloy': { name: 'Cobalt Alloy', isLinked: true },
  '/inventory/nickel': { name: 'Nickel', isLinked: true },
  '/services': { name: 'Services', isLinked: true },
  '/tools': { name: 'Tools', isLinked: true },
};

const headerImages = [
  {
    path: '/tools',
    image: `${envUrl}wp-content/uploads/2024/09/metal-supplier-tools-unit-conversion.jpg`,
  },
  {
    path: '/inventory',
    image: `${envUrl}wp-content/uploads/2024/09/alloy-steel-supplier-universal-metals.jpg`,
  },
  {
    path: '/services',
    image: `${envUrl}wp-content/uploads/2024/09/aerospace-metal-supplier-services.jpg`,
  },
];

const getPaths = pathname => {
  const paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce((prev, curr, index) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });

  return paths;
};

function Banner({ title, img, classes }) {
  const location = useLocation();
  const paths = getPaths(location.pathname);

  headerImages.forEach(headerObj => {
    const testPath = new RegExp(headerObj.path);

    if (testPath.test(location.pathname) && !img) {
      img = headerObj.image;
    }
  });

  if (!img) {
    img = `${envUrl}wp-content/uploads/2024/09/specialty-metals-and-services.jpg`;
  }

  const bannerStyles = img
    ? {
        background: `url(${img})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        filter: 'grayscale(1)',
        backgroundPositionY: '60%',
      }
    : null;

  return (
    <div
      className={['header-top', classes.banner].join(' ')}
      style={bannerStyles}
    >
      <div className="header-wrap">
        <h1 className={classes.bannerTitle}>
          {title || (
            <Skeleton className={classes.bannerSkel} width={120} height={60} />
          )}
        </h1>

        <Breadcrumbs aria-label="breadcrumb">
          {paths.map((path, i) => {
            // Ensure the path has a trailing slash
            const formattedPath = path.endsWith('/') ? path : `${path}/`;

            return pathsObj[path] && pathsObj[path].isLinked ? (
              <Link key={i} to={formattedPath}>
                {pathsObj[path].name}
              </Link>
            ) : null;
          })}
        </Breadcrumbs>
      </div>
    </div>
  );
}

const styles = theme => ({
  banner: {
    padding: '40px 0 40px',
    textAlign: 'center',
    color: 'white',
    background: '#362e69',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: 'grayscale(1)',
  },
  bannerTitle: {
    textShadow: '0px 2px #0000006b',
    margin: 0,
    lineHeight: 1,
    fontSize: 30,
    color: 'white',
    [theme.breakpoints.up('lg')]: {
      fontSize: 45,
    },
  },
  bannerSkel: { margin: 'auto' },
});

export default withStyles(styles)(Banner);
