import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';

import { wp, getCurrentMetal } from '../../configs/MetalConfig';
import { cache } from '../../utils/utils';

import Main from '../../components/layouts/main';

import './grade.scss';

function useWPApi(options = {}, deps = []) {
  const [state, setState] = useState({
    post: null,
    isLoading: false,
  });

  useEffect(() => {
    const cachedData = cache.get(options.cacheKey);
    if (cachedData) {
      setState(s => ({
        ...s,
        post: cachedData,
      }));
    } else {
      wp[options.apiType]()
        .param('slug', options.slug)
        .then(data => {
          cache.save(options.cacheKey, data[0]);
          setState(s => ({
            ...s,
            post: data[0],
          }));
        });
    }
  }, deps);

  return [state.isLoading, state.post];
}

// http://localhost:8080/wp-json/wp/v2/alloy_steel_specs?slug=ams-6359
function Grade({ match, formObj }) {
  const [otherGrades, setOtherGrades] = useState([]);
  const currentMetal = getCurrentMetal(match.params.metal);
  const gradeSlug = match.params.grade;

  const apiOptions = {
    apiType: currentMetal.shortName,
    slug: gradeSlug,
    cacheKey: gradeSlug,
  };
  const [, post] = useWPApi(apiOptions, [currentMetal.shortName, gradeSlug]);

  // useEffect(() => {
  //   wp[currentMetal.shortName]().then(data => setOtherGrades(data));
  // }, [currentMetal.shortName]);

  return (
    <div className="grade-page">
      <Main
        title={post && post.title ? post.title.rendered : null}
        layout="sidebar"
        postData={post}
        formObj={formObj}
      />
      {/* <Grid container>
        {otherGrades.map(grade => (
          <Grid item xs={6} md={4}>
            {grade.title.rendered}
          </Grid>
        ))}
      </Grid> */}
    </div>
  );
}

export default Grade;
